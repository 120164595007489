import { createRouter, createWebHistory } from 'vue-router'
import PublicLayout from '../views/layouts/PublicLayout.vue';
import ApiLayout from '../views/layouts/ApiLayout.vue';
import CabinetLayout from "../views/layouts/CabinetLayout.vue";

// eslint-disable-next-line no-prototype-builtins
const isAuthorized = localStorage.hasOwnProperty('token');
const authGuard = function (to, from, next){
  if(!isAuthorized) next({ name: 'Login' });
  else next()
}

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import('../views/default-pages/ChatPage')
  },
  {
    path: '/landing',
    name: 'Landing',
    component: () => import('../views/default-pages/LandingPage')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/default-pages/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/default-pages/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: () => import('../views/default-pages/ForgotPasswordView.vue')
  },
  {
    path: '/forgot-password-complete',
    name: 'ForgotComplete',
    component: () => import('../views/default-pages/ForgotPasswordComplete.vue')
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: () => import('../views/default-pages/SetPassword.vue')
  },
  {
    path: '/set-password/:token',
    name: 'SetPasswordWithToken',
    component: () => import('../views/default-pages/SetPasswordToken.vue')
  },
  {
    path: '/chat',
    name: 'ChatPage',
    component: () => import('../views/default-pages/ChatPage')
  },
  {
    path: '/ai-assistant',
    name: 'AiAssistant',
    component: () => import('../views/temporary-files/AiAssistantPage')
  },
  // {
  //   path: '/for-test-page',
  //   name: 'ForTestPage',
  //   component: () => import('../views/temporary-files/ForTestPage')
  // },
  {
    path: '/',
    component: PublicLayout,
    children: [
      {
        path: 'home',
        name: 'HomeSecond',
        component: () => import('../views/default-pages/HomeView'),
      },
      {
        path: 'dashboard',
        name: 'DashboardView',
        component: () => import('../views/default-pages/DashboardPage'),
      },
      {
        path: 'search/:chain/address/:address',
        name: 'NewSearchPage',
        component: () => import('../views/default-pages/WalletAnalyticsPage'),
      },
      {
        path: 'search/address/:address',
        name: 'NewSearchPageEthereum',
        component: () => import('../views/default-pages/WalletAnalyticsPage'),
      },
      {
        path: 'transaction/:chain/:hash',
        name: 'TransactionPage',
        component: () => import('../views/default-pages/TransactionView'),
      },
      {
        path: 'chain/:chain',
        name: 'ChainPage',
        component: () => import('../views/default-pages/ChainView'),
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicyPage',
        component: () => import('../views/default-pages/PrivacyPolicyPage'),
      },
      // {
      //   path: 'pricing',
      //   name: 'PricingPage',
      //   component: () => import('../views/default-pages/PricingPage')
      // },
      {
        path: 'privacy-score',
        name: 'PrivacyScore',
        component: () => import('../views/default-pages/PrivacyScorePage'),
      },
      {
        path: 'vc-tracker',
        name: 'VcTracker',
        component: () => import('../views/default-pages/VcView'),
      },
      {
        path: 'gamification',
        name: 'Gamification',
        component: () => import('../views/default-pages/GamificationPage'),
      },
      { path: "/:pathMatch(.*)*", component: () => import('../views/default-pages/NotFoundPage.vue')}
    ]
  },
  {
    path: '/api',
    component: ApiLayout,
    children: [
      {
        path: '',
        name: 'ApiIntroductionPage',
        component: () => import('../views/api-pages/IntroductionView'),
        meta:{
          activeNav: 1
        }
      },
      {
        path: 'wallet-analytics',
        name: 'ApiQuickStartWalletAnalytics',
        component: () => import('../views/api-pages/StartWalletAnalytics'),
        meta:{
          activeNav: 2
        }
      },
      {
        path: 'flow',
        name: 'ApiQuickStartFlow',
        component: () => import('../views/api-pages/StartFlow'),
        meta:{
          activeNav: 3
        }
      },
      {
        path: 'gpt',
        name: 'ApiQuickStartGpt',
        component: () => import('../views/api-pages/StartGpt'),
        meta:{
          activeNav: 6
        }
      },
      {
        path: 'get-transaction',
        name: 'GetTransaction',
        component: () => import('../views/api-pages/GetTransaction'),
        meta:{
          activeNav: 4
        }
      },
      {
        path: 'get-ethereum',
        name: 'GetEthereum',
        component: () => import('../views/api-pages/GetEthereum'),
        meta:{
          activeNav: 5
        }
      },
    ]
  },
  {
    path: '/api-integration',
    name: 'ApiPage',
    component: () => import('../views/api-pages/ApiIntegration')
  },
  {
    path: '/api-survey',
    name: 'ApiSurvey',
    component: () => import('../views/temporary-files/FormPage'),
    meta: {
      title: 'Oraclus API Integration Survey',
    }
  },
  {
    path: '/cabinet',
    component: CabinetLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'CabinetPage',
        component: () => import('../views/cabinet-pages/WatchlistView'),
        meta: {
          activeNav: "3",
        }
      },
      {
        path: 'watchlist',
        name: 'WatchlistPage',
        component: () => import('../views/cabinet-pages/WatchlistView'),
        meta: {
          activeNav: "3",
        }
      },
      {
        path: 'account',
        name: 'AccountPage',
        component: () => import('../views/cabinet-pages/SettingsLayout'),
        children: [
          {
            path: '',
            name: 'AccountGeneral',
            component: () => import('../views/cabinet-pages/AccountView'),
            meta: {
              activeNav: "2",
              settingsNum: "1"
            }
          },
          {
            path: 'connections',
            name: 'ConnectionsPage',
            component: () => import('../views/cabinet-pages/ConnectionsView'),
            meta: {
              activeNav: "2",
              settingsNum: "2"
            }
          },
        ]
      },
      {
        path: 'alerts',
        name: 'AlertsPage',
        component: () => import('../views/cabinet-pages/AlertsView'),
        meta: {
          activeNav: "4",
        }
      },
      {
        path: 'plans',
        name: 'PlansPage',
        component: () => import('../views/cabinet-pages/PlansView'),
        meta: {
          activeNav: "6",
        }
      },
      {
        path: 'custom-reports',
        name: 'CustomReports',
        component: () => import('../views/cabinet-pages/CustomReportsView'),
        meta: {
          activeNav: "7",
        }
      },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'GPT for Web3 | Oraclus';
  let link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', location.protocol + '//' + location.host + to.fullPath);
  document.head.appendChild(link);
})

export default router
